import ApplicationController from './application_controller'
import _ from 'lodash'
import { initMapbox } from "../packs/init_mapbox"

export default class extends ApplicationController {
  static targets = ["paginationSelect", "sortSelect", "filtersPanel", "jobShowModal"]

  connect () {
    super.connect()
  }

  openJobShowModal(event) {
    event.preventDefault()
    const jobId = event.currentTarget.dataset.jobId
    this.modalController.open(event)
    this.stimulate("JobsReflex#show_job", jobId)
        .then(_ => window.history.pushState({}, document.title, window.location.pathname + "/" + jobId))
        .then(_ => initMapbox())
  }

  onJobShowModalClose(event) {
    this.calculatorController.clearSession(event)
    event.preventDefault()
    window.history.pushState({}, document.title, '/jobs')
  }

  toggleFilters(event) {
    event.preventDefault()

    if (this.filtersPanelTarget.hidden) {
      this.filtersPanelTarget.hidden = false
      event.target.classList.add('button-select_active')
    } else {
      this.filtersPanelTarget.hidden = true
      event.target.classList.remove('button-select_active')
    }
  }

  togglePaginationSelect(event) {
    if (this.paginationSelectTarget.hidden) {
      this.paginationSelectTarget.hidden = false
    } else {
      this.paginationSelectTarget.hidden = true
    }
  }

  toggleSortSelect(event) {
    if (this.sortSelectTarget.hidden) {
      this.sortSelectTarget.hidden = false
    } else {
      this.sortSelectTarget.hidden = true
    }
  }

  setPerPage(event) {
    this.stimulate("JobsReflex#set_per_page", event.target.dataset.perPage)
    this.togglePaginationSelect()
    this.setFiltersUrl(this.element.dataset.activeFilters)
  }

  setSort(event) {
    const value = event.target.dataset.sort
    this.stimulate("JobsReflex#set_sort", value)
    this.toggleSortSelect()
    this.setFiltersUrl(this.element.dataset.activeFilters)
  }

  setSeniority(event) {
    const value = event.currentTarget.dataset.seniority
    this.stimulate("JobsReflex#set_seniority", value)
    this.setFiltersUrl(this.element.dataset.activeFilters)
  }

  setVisa(event) {
    const value = event.currentTarget.dataset.visa
    this.stimulate("JobsReflex#set_visa", value)
    this.setFiltersUrl(this.element.dataset.activeFilters)
  }

  setSalaries(event) {
    const value = event.currentTarget.dataset.salary
    this.stimulate("JobsReflex#set_salaries", value)
    this.setFiltersUrl(this.element.dataset.activeFilters)
  }

  addKeywords(event) {
    if (event.charCode === 13) {
      const value = event.target.value
      this.stimulate("JobsReflex#set_keywords", value, "add")
      this.setFiltersUrl(this.element.dataset.activeFilters)
    }
  }

  removeKeywords(event) {
    const value = event.currentTarget.dataset.keyword
    this.stimulate("JobsReflex#set_keywords", value, 'remove')
    this.setFiltersUrl(this.element.dataset.activeFilters)
  }

  toggleCategory(event) {
    if (event.currentTarget.classList.contains('chip_active')) {
      this.stimulate("JobsReflex#set_categories", event.target.dataset.categoryId, 'remove')
    } else {
      this.stimulate("JobsReflex#set_categories", event.target.dataset.categoryId, 'add')
    }
    this.setFiltersUrl(this.element.dataset.activeFilters)
  }

  turnThePage(event) {
    this.stimulate("JobsReflex#turn_the_page", event.target.dataset.page)
  }

  clearAllFilters(event) {
    event.preventDefault()

    this.stimulate("JobsReflex#clear_all_filters")
    this.setFiltersUrl(this.element.dataset.activeFilters)
  }

  get modalController() {
    return this.application.controllers.find(c => c.context.controller.identifier == 'modal')
  }

  get calculatorController() {
    return this.application.controllers.find(c => c.context.controller.identifier == 'calculator')
  }
}
