import ApplicationController from './application_controller'
import { useClickOutside } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ["body", "content", "wrapper"]

  connect() {
    super.connect()

    useClickOutside(this, { element: this.bodyTarget, onlyVisible: true })
  }

  open(event) {
    // to prevent `clickOutside()` call, cause open's click always happened outside of contentTarget
    if (event) { event.stopPropagation() }
    this.wrapperTarget.style.display = 'block'
  }

  close() {
    this.wrapperTarget.style.display = 'none'
    this.contentTarget.innerHTML = ''
  }
}
