import ApplicationController from './application_controller'
import debounced from 'debounced'

export default class extends ApplicationController {
  static targets = ["input", "list", "hidden"]
  static values = {
    resource: String,
    backend: String,
    autosubmit: Boolean,
  }

  connect() {
    super.connect()

    this.inputTarget.addEventListener('debounced:focusout', (event) => {
      this.listTarget.hidden = true
    });

    if (this.hasAutosubmitValue && this.autosubmitValue === true) {
      this.inputTarget.addEventListener('change', (event) => {
        if (event.target.value.length === 0) {
          this.stimulate("JobsReflex#find_jobs", null, this.resourceValue)
        }
      })
    }
  }

  fetch(_event) {
    if (this.inputTarget.value.length <= 2) return this.listTarget.hidden = true

    this.stimulate(this.backendValue, this.resourceValue, this.inputTarget.value)
  }

  afterFetch(_element, _reflex) {
    this.listTarget.hidden = false
  }

  removeList() {
    this.listTarget.hidden = true
  }

  insertValue(event) {
    const value = event.target.dataset.insertValue.replace(/<\/?[^>]+(>|$)/g, "")

    this.inputTarget.value = value

    if (this.hasHiddenTarget && event.target.dataset.insertHiddenValue) {
      this.hiddenTarget.value = event.target.dataset.insertHiddenValue
    }

    this.listTarget.hidden = true

    if (this.hasAutosubmitValue && this.autosubmitValue === true) {
      this.stimulate("JobsReflex#find_jobs", value, this.resourceValue)
    }
  }

  submit(event) {
    if (event.charCode === 13) {
      this.stimulate("JobsReflex#find_jobs", event.target.value.trim(), this.resourceValue)
    }
  }
}
