import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["error"]

  connect() {
    super.connect()
  }

  createSuccess(element, reflex, noop, reflexId) {
    this.errorTarget.innerHTML = ""
    this.modalController.open()
    this.stimulate('SubscriptionReflex#subscription_success_modal')
  }

  createError(element, reflex, noop, reflexId) {
    this.errorTarget.innerHTML = "<p style='font-size:10px;text-align:center;margin-top:-8%;color:red;'>invalid email</p>" 
  }

  create(event) {
    event.preventDefault()
    this.stimulate("SubscriptionReflex#create")
  }

  get modalController() {
    return this.application.controllers.find(c => c.context.controller.identifier == 'modal')
  }
}
