import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["jobCalculatorModal"]

  connect () {
    super.connect()
  }

  calculate(event) {
    event.preventDefault()

    this.modalController.open(event)
    this.stimulate("VisaCalculatorReflex#calculate")
  }

  clearSession(event) {
    event.preventDefault()
    this.stimulate("VisaCalculatorReflex#clear_session")
  }

  get modalController() {
    return this.application.controllers.find(c => c.context.controller.identifier == 'modal')
  }
}
