import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    super.connect()
  }

  createSuccess(element, reflex, noop, reflexId) {
    this.modalController.open()
    this.stimulate('CompanySubscriptionReflex#subscription_success_modal')
    this.element.reset()
  }

  create(event) {
    event.preventDefault()
    this.stimulate("CompanySubscriptionReflex#create")
  }

  get modalController() {
    return this.application.controllers.find(c => c.context.controller.identifier == 'modal')
  }
}
